import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import Logo from '../images/logo.inline.svg';
import {
  ContactAddress,
  ContactBankAccount,
  ContactInfo,
  ContactNumbers,
  ContactSocial,
  ContactLogo,
  PageTitle,
} from '../components/styles/global';

const Contact = () => {
  const { t } = useTranslation('translation');

  const {
    site: { siteData },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteData: siteMetadata {
            title
            description
            email
            address {
              street
              postal
              city
            }
            social {
              facebook
              instagram
            }
            account {
              bankName
              number
            }
          }
        }
      }
    `
  );
  const { address, email, social, account } = siteData;

  return (
    <>
      <SEO
        title={t('contact')}
        description={`${address.street} / ${address.postal} / ${address.city} / ${email}`}
      />
      <PageTitle>{t('contact')}</PageTitle>

      <div>
        <ContactLogo>
          <Logo />
        </ContactLogo>
        <ContactInfo>
          <ContactAddress>
            <span>{t('address')}:</span>
            <span>{address.street}</span>
            <span>
              {address.postal} {address.city}
            </span>
          </ContactAddress>
          <ContactSocial>
            <OutboundLink target="_blank" href={`mailto:${email}`}>
              {email}
            </OutboundLink>
            <OutboundLink target="_blank" href={`https://${social.facebook}`}>
              {social.facebook}
            </OutboundLink>
            <OutboundLink target="_blank" href={`https://${social.instagram}`}>
              {social.instagram}
            </OutboundLink>
          </ContactSocial>
          <ContactNumbers>
            <span>KRS 0000721336</span>
            <span>NIP 8741784157</span>
            <span>REGON 369618300</span>
          </ContactNumbers>
          <ContactBankAccount>
            <span>{t('bankAccount')}</span>
            <span>{account.bankName}</span>
            <span>{account.number}</span>
          </ContactBankAccount>
        </ContactInfo>
      </div>
    </>
  );
};
export default Contact;
